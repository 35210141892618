.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.tile-content {
  width: 100%;
  height: 100%;
}

bfc-base-page-layout {
  flex-direction: column; // to be deleted when/if @bfl/components are upgraded
}